/**
 * api接口统一管理
 */
import { get, post } from './ajax'
export const login = p => post('/login', p)
export const getStaffList = p => get('/staff/list', p)
export const getQuestion = p => get('/question/get', p)
export const storeResult = p => post('/result/store', p)
export const stroeRecord = p => post('/record/set', p)
export const updateResult = p => post('/result/update', p)
export const getScore = p => get('/score/get', p)
export const getCob = p =>get('/cob/get', p)
export const chkResult = p =>get('/result/check', p)
export const getResult = p => get('/result/get', p)
